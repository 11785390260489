import { ColData } from 'ufinet-web-components'
import { IntlShape } from 'react-intl'
import { ActionsButtons, OverdueRender, StateRender, FormattedDate } from 'lib'
import { ModuleInvoicesTypes } from '../../types/module-invoices/module-invoices';
import { OverdueStates, overdueStatesMapping, PendingStates, pendingStatesMapping } from 'types/typesAndValues';
import { convertNumber } from 'utils/convertNumber/ConvertNumber';



export const colsModuleInvoices = (intl: IntlShape): ColData[] => [
    {
        field: 'invoice',
        header: 'COL.INVOICE',
        width: '190px',
        editable: false,
        nonFilterable: false,
        nonSortable: false,
        filterType: 'text'
    },

    {
        field: 'transDate',
        header: 'COL.DATE',
        width: '190px',
        editable: false,
        nonFilterable: true,
        nonSortable: false,
        filterType: 'text',
        body: (row: ModuleInvoicesTypes) => <FormattedDate date={row.transDate} />
    },
    {
        field: 'dueDate',
        header: 'COL.DUEDATE',
        width: '190px',
        editable: false,
        nonFilterable: true,
        nonSortable: false,
        filterType: 'text',
        body: (row: ModuleInvoicesTypes) => <FormattedDate date={row.dueDate} />
    },
    {
        field: 'amountCur',
        header: 'COL.AMOUNTCUR',
        width: '190px',
        editable: false,
        nonFilterable: true,
        nonSortable: false,
        filterType: 'text',
        body: (row: ModuleInvoicesTypes) => <>{`${convertNumber(row.amountCur)} ${row.currencyCode}`}</>
    },
    {
        field: 'isPending',
        header: 'COL.STATUS',
        width: '190px',
        editable: false,
        nonFilterable: false,
        nonSortable: false,
        filterType: 'select',
        filterOptions: [
            { value: PendingStates.PENDING, label: intl.formatMessage({ id: pendingStatesMapping.get(PendingStates.PENDING) }) },
            { value: PendingStates.PAID, label: intl.formatMessage({ id: pendingStatesMapping.get(PendingStates.PAID) }) },
            { value: PendingStates.PARTIAL, label: intl.formatMessage({ id: pendingStatesMapping.get(PendingStates.PARTIAL) }) },
        ],
        body: (row: ModuleInvoicesTypes) => <StateRender state={row.isPending} />
    },
    {
        field: 'isOverdue',
        header: 'COL.OVERDUE',
        width: '190px',
        editable: false,
        nonFilterable: false,
        nonSortable: false,
        filterType: 'select',
        filterOptions: [
            { value: OverdueStates.YES, label: intl.formatMessage({ id: overdueStatesMapping.get(OverdueStates.YES) }) },
            { value: OverdueStates.NO, label: intl.formatMessage({ id: overdueStatesMapping.get(OverdueStates.NO) }) },
        ],
        body: (row: ModuleInvoicesTypes) => <OverdueRender state={row.isOverdue} />
    },

    {
        field: 'billingSociety',
        header: 'COL.BILLINGSOCIETY',
        width: '190px',
        editable: false,
        nonFilterable: false,
        nonSortable: false,
        filterType: 'text'
    },
    {
        field: 'clientAccount',
        header: 'COL.CLIENTACCOUNT',
        width: '190px',
        editable: false,
        nonFilterable: false,
        nonSortable: false,
        filterType: 'text'
    },
    {
        field: 'isOverdue',
        header: 'COL.ACTIONS',
        width: '200px',
        editable: false,
        nonFilterable: true,
        nonSortable: true,
        filterType: 'text',
        body: (row: ModuleInvoicesTypes) => <ActionsButtons data={row} />
    },

]


