import { createContext } from "react";



interface MaintenanceContext {
    startDate?: string
    endDate?: string
    maintenanceDescription?: string
    isUnderMaintenance?: boolean
    isLoading?: boolean
    daysLeft?: boolean
}



export const MaintenanceContext = createContext({} as MaintenanceContext)