import { ReactNode, useContext, useEffect, useState } from "react";
import { MaintenanceContext } from "./MaintenanceContext";
import { GetMaintenance } from "request";
import { AuthContext, AuthStatus } from "ufinet-web-functions";


interface Props {
    children: ReactNode | ReactNode[]
}

// {
//     "startDate": "2024-08-16T00:00:00",
//     "endDate": "2024-08-30T00:00:00",
//     "isUnderMaintenance": true,
//     "maintenanceDescription": "<p><strong>Mantenimiento Programado</strong></p><p>El sistema estará fuera de servicio para mejoras y actualizaciones.</p><p>Gracias por su comprensión.</p>"
//   }


interface DataTypes {
    startDate: string
    endDate: string
    maintenanceDescription: string
    isUnderMaintenance: boolean
}


export const MaintenanceProvider = ({ children }: Props) => {


    const { status } = useContext(AuthContext)

    const { data, isLoading } = GetMaintenance()


    const start = data && new Date(data.startDate);
    const end = data && new Date(data?.endDate);
    const today = new Date();


    const options: Intl.DateTimeFormatOptions = {
        hour12: false,
        minute: '2-digit',
        hour: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',

    }
    const formattedStartDate = start?.toLocaleString('en-GB', options);
    const formattedEndDate = end?.toLocaleString('en-GB', options);


    let dateDiff = false
    if (start) {
        const time = start.getTime() - today.getTime()
        const daysLeft = Math.ceil(time / (1000 * 60 * 60 * 24))
        dateDiff = daysLeft >= 1 && daysLeft <= 5
    }





    return (
        <MaintenanceContext.Provider value={status === AuthStatus.LOGGED_IN ?
            {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                maintenanceDescription: data?.maintenanceDescription,
                isUnderMaintenance: data?.isUnderMaintenance,
                daysLeft: dateDiff,
                isLoading
            } : {}
        }>
            {children}
        </MaintenanceContext.Provider>
    )
}