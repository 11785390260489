import { MaintenanceContext } from "context/maintenanceContext/MaintenanceContext"
import { useContext } from "react"
import { UfinetBox, UfinetSectionBox } from "ufinet-web-components"
import { useTranslation } from "utils/translation/Translation"


export const MaintenancePage = () => {
    const { endDate, startDate } = useContext(MaintenanceContext)


    const translate = useTranslation()



    return (
        <UfinetBox className="h-100">
            <UfinetSectionBox className="h-100 d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div>
                                <p className="fw-bolder">{translate('MAINTENANCE.MESSAGE1')}</p>
                                <p>{translate('MAINTENANCE.MESSAGE2')}</p>
                                <p>{translate('MAINTENANCE.MESSAGE3')}</p>
                            </div>
                        </div>

                        <div className="col-12 text-center ">
                            <div>
                                {`${startDate} - ${endDate}`}
                            </div>
                        </div>
                    </div>
                </div>
            </UfinetSectionBox>
        </UfinetBox>
    )
}
