import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { IUfinetSelectOption, Table, UfinetActionButton, UfinetBox, UfinetSectionBox, UfinetSelect, UfinetSelectHandle, formatTableFilters, tableSortValueToSortDirection } from 'ufinet-web-components';
import { colsModuleInvoices } from './ModuleInvoiceColData';
import { useIntl } from 'react-intl';
import { useTranslation } from 'utils/translation/Translation';
import { ModuleInvoicesTypes, LegalEntitiesTypes } from '../../types/module-invoices/module-invoices';
import { DataTablePageEvent } from 'primereact/datatable';
import { AuthContext, Authority, useInternalUser } from 'ufinet-web-functions';
import { useGetModuleInvoices, GetLegalEntities, UseGetReport } from 'request';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export const ModuleInvoicePage = () => {
    const [filteredData, setFilteredData] = useState({})
    const [selectedValues, setSelectedValues] = useState<ModuleInvoicesTypes[]>([])
    const [paging, setPaging] = useState<any>()
    const isInternalUser = useInternalUser()



    const { data: dataModuleInvoices, isLoading: isLoadingModuleInvoices } = useGetModuleInvoices(filteredData, !isInternalUser)


    const dataAreaId = 'dataAreaId'

    const isData = dataModuleInvoices?.data.length > 0

    const isFilteredData = filteredData && Object.keys(filteredData).length > 0
    const isDataAreaId = isFilteredData && Object.keys(filteredData).includes(dataAreaId)




    const intl = useIntl()
    const translate = useTranslation()



    const selectLegalEntityRef = useRef<UfinetSelectHandle>(null)

    const colsInvoices = useMemo(() => colsModuleInvoices(intl), [intl])

    const authData = useContext(AuthContext)

    const roles = authData.userData?.authorities || []
    const permissions = Authority.getGfaInvoicePermissions(roles)


    const cbSuccessDownloadFile = ({ data }: any) => {

    }


    const { data: legalEntities, isLoading: isLoadingLegalEntities } = GetLegalEntities(isInternalUser)
    const { mutate: downloadFile, isLoading: isLoadingDowloadingFile } = UseGetReport(cbSuccessDownloadFile)


    const legalEntittiesSelect = legalEntities?.data.map((item: LegalEntitiesTypes) => (
        {
            value: item.partnershipId,
            label: item.partnershipName
        }
    ))









    useEffect(() => {
        if (dataModuleInvoices) {
            const parsePagination = JSON.parse(dataModuleInvoices?.headers['x-pagination'])
            const formattedPagination = {
                totalElements: parsePagination.totalCount,
                totalPages: parsePagination.totalPages,
                pageSize: parsePagination.pageSize,
                pageNumber: parsePagination.currentPage,
            }
            setPaging(formattedPagination)
        }
    }, [dataModuleInvoices])



    const onFilterClear = (): void => {
        setFilteredData({})
        selectLegalEntityRef.current?.clearSelect()
    }

    const onFilter = (e: DataTablePageEvent): void => {
        const formattedFilters = formatTableFilters(e as any)
        setFilteredData({
            ...filteredData,
            ...formattedFilters,
        })

    }

    const onPage = (e: DataTablePageEvent): void => {

        setFilteredData({
            ...filteredData,
            pageNumber: e.page,
            pageSize: e.rows,

        })
    }

    const onSort = (e: DataTablePageEvent): void => {
        const finalOrder = tableSortValueToSortDirection(e.sortOrder)
        setFilteredData({
            ...filteredData,
            sortField: e.sortField,
            sortOrder: finalOrder,

        })
    }

    const onSelectionChange = useCallback((values: any[]) => {
        setSelectedValues(values)
    }, [])


    const getHeadersFilters = () => {
        return (
            <>
                {permissions.canRead && isInternalUser && (
                    <UfinetSelect
                        id="dataAreaId"
                        placeholder={translate('INVOICE.FILTER.LEGAL_ENTITY')}
                        labelTitle={translate('INVOICE.FILTER.LEGAL_ENTITY')}
                        // value={}
                        shouldClearDefaultValue
                        options={legalEntittiesSelect}
                        onChange={(value) => {
                            setFilteredData({
                                ...filteredData,
                                dataAreaId: (value as IUfinetSelectOption).value,
                            })
                        }}
                        closeMenuOnSelect={true}
                        ref={selectLegalEntityRef}
                        isLoadingOptions={isLoadingLegalEntities}
                        isSearchable={false}
                    ></UfinetSelect>
                )}
            </>
        )
    }


    const getHeaderButtons = () => {
        return (
            <>
                {permissions.canRead &&
                    (
                        <UfinetActionButton
                            isDisabled={!isDataAreaId || isLoadingDowloadingFile || !isData}
                            onClick={() => downloadFile(filteredData)}
                            icon={faDownload}
                            content={translate('BUTTON.DOWNLOAD')}
                        />
                    )

                }
            </>
        )
    }

    return (
        <>
            <UfinetBox>
                <UfinetSectionBox>


                    <Table
                        filterButtons={getHeadersFilters()}
                        headerButtons={getHeaderButtons()}
                        enableSearchFilter={false}
                        selectedValues={selectedValues}
                        // onSelectionChange={permissions.canUpdate ? onSelectionChange : undefined}
                        content={dataModuleInvoices?.data}
                        cols={colsInvoices}
                        emptyMessage={isLoadingModuleInvoices || isLoadingDowloadingFile ? translate('LOADING_DOTS') : undefined}
                        actionBodyTemplate={<></>}
                        onFilterClear={onFilterClear}
                        lazySettings={
                            paging && {
                                ...paging,
                                loading: isLoadingModuleInvoices || isLoadingDowloadingFile,
                                onFilter,
                                onPage,
                                onSort,
                            }
                        }
                    />
                </UfinetSectionBox>
            </UfinetBox>
        </>
    )
}
