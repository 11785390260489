import { convertNumber } from "utils/convertNumber/ConvertNumber"

interface Props {
    amount: number
    currencyCode: string
}

export const FormattedValue = ({ amount, currencyCode }: Props) => {

    if (amount === null || currencyCode === null) return <></>

    return (
        <>{`${convertNumber(amount)} ${currencyCode}`}</>
    )
}
