/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { AsideMenuMain } from './AsideMenuMain'

import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { KTSVG } from 'ufinet-web-components'
import { PATH_HOME } from '../../routing/protected/PrivateRoutes'
import { useTranslator } from 'ufinet-web-functions'

// The KT aside toggle is being troublesome, for now,
// we'll check and toggle it manually manipulating body attributes.
const bodyAsideToggleAttribute = 'data-kt-aside-minimize'
export const toggleSidebar = () => {
	const minimized = document.body.getAttribute(bodyAsideToggleAttribute)
	const newMinimizedValue = minimized === 'on' ? 'off' : 'on'
	document.body.setAttribute(bodyAsideToggleAttribute, newMinimizedValue)
	document.getElementById('kt_aside_toggle')?.classList.toggle('reverse')
}

const AsideDefault: FC = () => {


	const translate = useTranslator()
	return (
		<div
			id="kt_aside"
			className="aside ufinet-aside shadow-lg overflow-auto"
			data-kt-drawer="true"
			data-kt-drawer-name="aside"
			data-kt-drawer-activate="{default: true, lg: false}"
			data-kt-drawer-overlay="true"
			data-kt-drawer-width="{default:'200px', '300px': '250px'}"
			data-kt-drawer-direction="start"
			data-kt-drawer-toggle="#kt_aside_mobile_toggle"
		>
			{/* begin::Brand */}
			<div className="aside-logo flex-column-auto justify-content-center" id="kt_aside_logo">
				{/* begin::Logo */}
				<Link to={PATH_HOME}>
					<div className="d-flex align-items-center">
						<FontAwesomeIcon className="logo-minimize color-primary" size="3x" icon={faUserTie} />
						<h1 className="logo fw-boldest my-auto ms-4 text-primary">{translate('MENU.MODULE.INVOICE')}</h1>
					</div>
				</Link>
				{/* end::Logo */}
			</div>
			{/* end::Brand */}

			{/* begin::Aside menu */}
			<div className="aside-menu flex-column-fluid">
				<div
					id="kt_aside_menu_wrapper"
					className="hover-scroll-overlay-y my-5 my-lg-5"
					data-kt-scroll="true"
					data-kt-scroll-activate="{default: false, lg: true}"
					data-kt-scroll-height="auto"
					data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
					data-kt-scroll-wrappers="#kt_aside_menu"
					data-kt-scroll-offset="0"
				>
					<div id="#kt_aside_menu" data-kt-menu="true" className="menu menu-column">
						<AsideMenuMain />
					</div>
				</div>
			</div>
			{/* end::Aside menu */}
			<div

				onClick={toggleSidebar}
				id="kt_aside_toggle"
				className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle reverse"
				// data-kt-toggle="true"
				data-kt-toggle-state="active"
				data-kt-toggle-target="body"
				data-kt-toggle-name="aside-minimize"
			>
				<KTSVG path={'/media/icons/duotune/arrows/arr080.svg'} className={'svg-icon-1'} />
			</div>
		</div>
	)
}

export { AsideDefault }
