import { useTranslation } from "utils/translation/Translation"

interface Props {
    state: string
}

export const StateRender = ({ state }: Props) => {

    const translate = useTranslation()
    return <> {translate(state)}</>
}
