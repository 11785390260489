import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faEye, faInfo } from "@fortawesome/free-solid-svg-icons"
import { useModal } from "ufinet-web-functions"
import { UfinetModal } from "ufinet-web-components"
import { ModuleInvoicesTypes } from "types/module-invoices/module-invoices"
import { useTranslation } from "utils/translation/Translation"
import axios from "axios"
import * as XLSX from 'xlsx'

import { UseGetZipFiles } from "request"
import { convertNumber } from "utils/convertNumber/ConvertNumber"
import { FormattedValue } from "lib/formatted-value/FormattedValue"


interface Props {
    data: ModuleInvoicesTypes
}

export const ActionsButtons = ({ data }: Props) => {
    const [modal, showModal, hideModal] = useModal()
    const [modalDocuments, showModalDocuments, hideModalDocuments] = useModal()



    const cbSuccessZipFiles = ({ data }: any) => {

    }


    const { mutate: getZipFiles, isLoading: isLoadingZipFiles } = UseGetZipFiles(cbSuccessZipFiles)



    const dataGetFiles = {
        dataAreaId: data?.dataAreaId,
        recId: data?.recId,
        invoice: data?.invoice,
    }

    const downloadZipFile = () => {
        if (data.dataAreaId && data.recId && data.invoice) {
            getZipFiles(dataGetFiles)
        }
    }

    const translate = useTranslation()


    const isDocuments = data && data.documents

    const pdfTitles = isDocuments && data?.documents?.pdf?.map((item: { url: string, title: string }) => (
        item.title
    ))
    const xmlTitles = isDocuments && data?.documents?.xml?.map((item: { url: string, title: string }) => (
        item.title
    ))
    const xlsxTitles = isDocuments && data?.documents?.xlsx?.map((item: { url: string, title: string }) => (
        item.title
    ))




    const handleExcelFile = async (url: string) => {
        try {
            const response = await axios.get(url, {
                responseType: 'arraybuffer',
            })
            const data = new Uint8Array(response.data)
            const workbook = XLSX.read(data, { type: 'array' })
            return workbook
        } catch (error) {
            console.error('Error fetching the Excel file:', error)
            return null
        }
    }

    const openExcelInNewTab = async () => {
        try {
            const documentTitle = data?.documents?.xlsx[0]?.title.split('.')[0]
            const workbook = await handleExcelFile(data?.documents?.xlsx[0].url)

            if (workbook) {
                const newWindow = window.open('', '_blank')
                if (newWindow) {
                    newWindow.document.open()
                    newWindow.document.write(`
            <html>
              <head>
                <title>Excel File</title>
                <style>
                  body { font-family: Arial, sans-serif; margin: 20px; }
                  h3 { margin-top: 20px; }
                  table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
                  table, th, td { border: 1px solid black; }
                  th, td { padding: 8px; text-align: left; }
                </style>
              </head>
              <body>
              <h1>${documentTitle}</h1>
          `)

                    workbook.SheetNames.map((sheetName, index) => {
                        const worksheet = workbook.Sheets[sheetName]
                        const html = XLSX.utils.sheet_to_html(worksheet, { id: sheetName })
                        newWindow.document.write(`<h3>Sheet ${index + 1}: ${sheetName}</h3>`)
                        newWindow.document.write(html)
                    })

                    newWindow.document.write('</body></html>')
                    newWindow.document.close()
                }
            }
        } catch (error) {
            console.error('Error processing the Excel file:', error)
        }
    }

    const openPDFsInNewTab = async () => {
        try {
            const pdfContents = await Promise.all(
                data.documents.pdf.map(async (file) => {
                    const response = await axios.get(file.url, {
                        responseType: 'blob',
                    })
                    const blob = new Blob([response.data], { type: 'application/pdf' })
                    const blobUrl = URL.createObjectURL(blob)
                    return { url: blobUrl, title: file.title.split('.')[0] }
                })
            )

            const newWindow = window.open('', '_blank')
            newWindow?.document.open()
            newWindow?.document.write(
                `<div>${pdfContents
                    .map(
                        ({ url, title }, index: any) =>
                            `<h3>${title}</h3><iframe src="${url}" width="100%" height="100%" title="pdf-${title}"></iframe>`
                    )
                    .join('<br/><br/>')}</div>`
            )
            newWindow?.document.close()
        } catch (error) {
            console.error('Error fetching the PDF files:', error)
        }
    }

    const escapeHtml = (unsafe: any) => {
        return unsafe
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;')
    }

    const openXMLInNewTab = async () => {
        try {
            const xmlContents = await Promise.all(
                data.documents.xml.map(async (file) => {
                    const response = await axios.get(file.url, {
                        responseType: 'text',
                    })
                    return { title: file.title.split('.')[0], content: response.data }
                })
            )
            const newWindow = window.open('', '_blank')
            newWindow?.document.write(
                `
        <html>
            <head>
              <title>XML Viewer</title>
              <style>
                body { font-family: Arial, sans-serif; margin: 20px; }
                h3 { margin-top: 20px; }
                pre { background-color: #f4f4f4; padding: 10px; border: 1px solid #ccc; overflow-x: auto; }
                code { white-space: pre-wrap; }
              </style>
            </head>
            <body>
              ${xmlContents
                    .map(
                        (xml, index) => `
                <h3>XML File ${xml.title}</h3>
                <pre><code>${escapeHtml(xml.content)}</code></pre>
              `
                    )
                    .join('<br/><br/>')}
            </body>
          </html>
        `
            )
            newWindow?.document.close()
        } catch (error) {
            console.error('Error fetching the XML files:', error)
        }
    }

    const styles = {
        height: '125px'
    }


    return (
        <div>

            <Button
                onClick={showModal}
                className="">
                <FontAwesomeIcon icon={faInfo} />
            </Button>

            <Button
                onClick={showModalDocuments}
                className="mx-1">
                <FontAwesomeIcon icon={faEye} />
            </Button>

            <Button
                onClick={downloadZipFile}
                disabled={isLoadingZipFiles}
                className="">
                <FontAwesomeIcon icon={faDownload} />
            </Button>

            <UfinetModal size="lg" show={modal} handleClose={hideModal} title={translate("COL.MOREDETAILS")}>
                <div className="container">

                    <div className="row">

                        <div className="col">
                            <p className="fw-bolder">{translate("COL.REPORTING")}</p>
                        </div>
                        <div className="col">
                            <p className="fw-bolder">{translate("PAID.AMOUNT")}</p>
                        </div>
                        <div className="col">
                            <p className="fw-bolder">{translate("COL.PENDINGAMOUNT")}</p>
                        </div>
                        <div className="col">
                            <p className="fw-bolder">{translate("COL.DAYSUNTILDUE")}</p>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col text-break"><FormattedValue amount={data.amountCur} currencyCode={data.currencyCode} /></div>
                        <div className="col text-break"><FormattedValue amount={data.paidAmount} currencyCode={data.currencyCode} /></div>
                        <div className="col text-break"><FormattedValue amount={data.pendingAmount} currencyCode={data.currencyCode} /></div>

                        <div className="col">{data.daysUntilDue}</div>

                    </div>

                </div>
            </UfinetModal>


            <UfinetModal size='lg' show={modalDocuments} handleClose={hideModalDocuments} title={translate("COL.VISUALIZE")}>
                <div className="container">

                    <div className="row">
                        <div className="col-4">
                            <p className="fw-bolder h5">{`${translate('COL.PDF')} (${data?.documents?.pdf.length})`}</p>
                        </div>
                        <div className="col-4">
                            <p className="fw-bolder h5">{`${translate('COL.XML')} (${data?.documents?.xml.length})`}</p>
                        </div>
                        <div className="col-4">
                            <p className="fw-bolder h5">{`${translate('COL.XLSX')} (${data?.documents?.xlsx.length})`}</p>
                        </div>
                    </div>


                    <div className="row">

                        <div className="col-4 text-break">
                            <div style={styles} className="d-flex flex-column justify-content-between">
                                <div>
                                    {pdfTitles.map((item: any) => (
                                        <p className="m-0" key={item}>{item}</p>
                                    ))}
                                </div>
                                {isDocuments && data.documents.pdf.length > 0 ? (
                                    <Button onClick={openPDFsInNewTab}>{`${translate('COL.TOSEE')}`}</Button>
                                ) : <>{translate('NO.DOCUMENTS')}</>}
                            </div>
                        </div>
                        <div className="col-4 text-break">
                            <div style={styles} className="d-flex flex-column justify-content-between">
                                <div>
                                    {xmlTitles.map((item: any) => (
                                        <p className="m-0" key={item}>{item}</p>
                                    ))}
                                </div>

                                {isDocuments && data.documents.xml.length > 0 ? (
                                    <Button onClick={openXMLInNewTab}>{`${translate('COL.TOSEE')}`}</Button>
                                ) : <>{translate('NO.DOCUMENTS')}</>}
                            </div>

                        </div>
                        <div className="col-4 text-break">
                            <div style={styles} className="d-flex flex-column justify-content-between">
                                <div>
                                    {xlsxTitles.map((item: any) => (
                                        <p className="m-0" key={item}>{item}</p>
                                    ))}
                                </div>
                                {isDocuments && data.documents.xlsx.length > 0 ? (
                                    <Button onClick={openExcelInNewTab}>{`${translate('COL.TOSEE')}`}</Button>
                                ) : <>{translate('NO.DOCUMENTS')}</>}

                            </div>
                        </div>







                    </div>

                </div>
            </UfinetModal>

        </div>
    )
}
