import { useMutation, useQuery } from "react-query"
import { fetchGET } from "utils/https/https"


export const useGetModuleInvoices = (data: any, isInternalUser: boolean) => {


    const invoice = data?.invoice ? data.invoice : null
    const billingSociety = data?.billingSociety ? data.billingSociety : null
    const clientAccount = data?.clientAccount ? data.clientAccount : null
    const isPending = data?.isPending ? data.isPending[0] : null
    const isOverdue = data?.isOverdue ? data.isOverdue[0] : null
    const dataAreaId = data?.dataAreaId ?? null


    const pageNumber = data?.pageNumber
    const pageSize = data?.pageSize
    const sortOrder = data?.sortOrder ? data.sortOrder : null
    const sortField = data?.sortField ? data.sortField : null

    return useQuery(['useGetModuleInvoices', invoice, clientAccount, billingSociety, dataAreaId, isPending, isOverdue, pageNumber, pageSize, sortOrder, sortField], async () => {


        const serchParms = new URLSearchParams();

        if (invoice) {
            serchParms.append('invoice', invoice)
        }

        if (clientAccount) {
            serchParms.append('clientAccount', clientAccount)
        }

        if (billingSociety) {
            serchParms.append('billingSociety', billingSociety)
        }

        if (isPending) {
            serchParms.append('isPending', isPending)
        }

        if (isOverdue) {
            serchParms.append('isOverdue', isOverdue)
        }

        if (dataAreaId) {
            serchParms.append('dataAreaId', dataAreaId)
        }



        // PAGINATION
        if (pageNumber) {
            serchParms.append('pageNumber', pageNumber)
        }

        if (pageSize) {
            serchParms.append('rowsPerPage', pageSize)
        }

        if (sortOrder) {
            serchParms.append('sortOrder', sortOrder)
        }

        if (sortField) {
            serchParms.append('sortField', sortField)
        }

        try {

            const response = await fetchGET(`invoice/getInvoices?&${serchParms.toString()}`)
            return response

        } catch (error) {
            throw error
        }
    },
        {
            enabled: !!isInternalUser || !!dataAreaId
        }
    )



}

export const GetLegalEntities = (isInternalUser: boolean) => {
    return useQuery(['GetLegalEntities'], async () => {
        try {
            const response = await fetchGET('invoice/getLegalEntities')
            return response
        } catch (error) {
            throw error
        }
    },
        {
            enabled: !!isInternalUser
        }
    )
}



const getFiles = async (data: { recId: number, dataAreaId: string, invoice: string }) => {


    try {
        const response = await fetchGET(`invoice/downloadInvoiceDocuments?&DataAreaId=${data.dataAreaId}&&RecId=${data.recId}`, { responseType: 'blob' })


        const href = URL.createObjectURL(response.data);


        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', data.invoice);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        return response
    } catch (error) {
        throw error
    }
}


export const UseGetZipFiles = (cb: any) => {
    return useMutation(getFiles, {
        onSuccess: (data: any) => {
            cb(data)
        }
    })
}



const getReport = async (data: any) => {


    const invoice = data?.invoice ? data.invoice : null
    const billingSociety = data?.billingSociety ? data.billingSociety : null
    const clientAccount = data?.clientAccount ? data.clientAccount : null
    const isPending = data?.isPending ? data.isPending[0] : null
    const isOverdue = data?.isOverdue ? data.isOverdue[0] : null
    const dataAreaId = data?.dataAreaId ?? null

    try {

        const serchParms = new URLSearchParams();

        if (invoice) {
            serchParms.append('invoice', invoice)
        }

        if (clientAccount) {
            serchParms.append('clientAccount', clientAccount)
        }

        if (billingSociety) {
            serchParms.append('billingSociety', billingSociety)
        }

        if (isPending) {
            serchParms.append('isPending', isPending)
        }

        if (isOverdue) {
            serchParms.append('isOverdue', isOverdue)
        }

        if (dataAreaId) {
            serchParms.append('dataAreaId', dataAreaId)
        }

        const response = await fetchGET(`invoice/Downloadinvoices?&${serchParms.toString()}`, { responseType: 'blob' })


        const href = URL.createObjectURL(response.data);


        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'invoicesDocument.xlsx');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        return response
    } catch (error) {
        throw error
    }
}


export const UseGetReport = (cb: any) => {
    return useMutation(getReport, {
        onSuccess: (data: any) => {
            cb(data)
        }
    })
}