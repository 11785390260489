import axios from 'axios'
import { getUsername } from './getUsername'

const https = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		apikey: process.env.REACT_APP_API_KEY,
	},
})

const fetchGET = async (endpoint: string, configOverride: object | null = null) => {
	const { username, accessToken } = await getUsername()

	let config = {
		headers: {
			user: username,
			token: accessToken,
		},
	}

	if (configOverride !== null) {
		config = { ...config, ...configOverride };
	}

	const response = await https.get(endpoint, config)
	return response
}

const fetchPOST = async (endpoint: string, data: any) => {
	const { username, accessToken } = await getUsername()

	let config = {
		headers: {
			email: username,
			token: accessToken
		},
	}

	const response = await https.post(endpoint, data, config)
	return response
}

export { fetchGET, fetchPOST }
