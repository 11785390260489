import { AsideDefault } from 'layout/aside/AsideDefault'
import { AsideMobile } from 'layout/aside/AsideMobile'
import { FC, useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AuthRoute, MasterLayout, WelcomePage } from 'ufinet-web-components'
import { Authority, useTranslator } from 'ufinet-web-functions'
import { MaintenanceContext } from 'context/maintenanceContext/MaintenanceContext'



//* Module invoices
import { ModuleInvoicePage } from '../../pages/module-invoice/ModuleInvoicePage';
import { MaintenancePage } from 'pages/maintenance/MaintenancePage'
import { toast } from 'react-toastify'








export const PATH_HOME = ''
export const PATH_MAINTENANCE = 'maintenance'

export const PATH_MODULE = 'module'
export const PATH_MODULE_INVOICE_PAGE = 'module-invoice'
export const PATH_MODEL_MODULE_INVOICE_PAGE = `${PATH_MODULE}/${PATH_MODULE_INVOICE_PAGE}`






export const routerNameMap = new Map<string, string>([
	[PATH_HOME, 'PATH_HOME'],
	[PATH_MAINTENANCE, 'PATH_MAINTENANCE'],

	//* COMMISSIONS
	[PATH_MODULE, 'TITLE.INVOICES'],
	[PATH_MODULE_INVOICE_PAGE, 'DETAILS.INVOICES'],
])

export const routerLinkMap = new Map<string, string>([
	// [PATH_HOME, `/${PATH_HOME}`],
	// [PATH_MAINTENANCE, `/${PATH_MAINTENANCE}`],
	// [PATH_INVOICES, `/${PATH_INVOICES}`],
	// [PATH_COMMISSIONS, `/${PATH_COMMISSIONS}`],
])

export const routePermissionMap = new Map<string, Authority[]>([

	//* MODULE INVOICES
	[PATH_MODULE, [Authority.facturasModuleRead]],
	[PATH_MODULE_INVOICE_PAGE, [Authority.facturasModuleRead]],

])





const PrivateRoutes: FC = () => {
	const translate = useTranslator()
	const location = useLocation()


	const { isUnderMaintenance, daysLeft, startDate, endDate } = useContext(MaintenanceContext)

	useEffect(() => {

		if (startDate && endDate && daysLeft) {
			toast.warning(`${translate('MAINTENANCE.MESSAGE')} ${startDate}  ${translate('MAINTENANCE.FINISH')} ${endDate}`, {
				toastId: 'toast-message-maintenance',
				autoClose: false,
				closeOnClick: false,
				position: 'bottom-right',
				className: 'text-light bg-warning fw-bolder',
				icon: false,


			})
		}
	}, [daysLeft, location.pathname, isUnderMaintenance])







	return (
		<>
			<Routes>
				<Route
					element={
						<MasterLayout
							routerLocation={location}
							asideDefault={<AsideDefault />}
							routerNameMap={routerNameMap}
							asideMobile={<AsideMobile />}
						/>
					}
				>



					{

						isUnderMaintenance
							?
							<>
								<Route path={PATH_MAINTENANCE} element={<MaintenancePage />} />
								<Route path='*' element={<Navigate to={PATH_MAINTENANCE} replace />} />
							</>
							:
							<>
								<Route path={PATH_HOME} element={<WelcomePage appName={translate('MENU.MODULE.INVOICE')} />} />

								<Route
									path={PATH_MODEL_MODULE_INVOICE_PAGE}
									element={
										<AuthRoute
											authorizedRoles={routePermissionMap.get(PATH_MODULE_INVOICE_PAGE)!}
										>
											<ModuleInvoicePage />
										</AuthRoute>
									}
								/>

								<Route path={'*'} element={<Navigate to={PATH_HOME} replace />} />
							</>
					}





				</Route>
			</Routes>
		</>
	)
}

export { PrivateRoutes }
