import { useQuery } from "react-query"
import { fetchGET } from "utils/https/https"


interface DataTypes {
    startDate: Date
    endDate: Date
    maintenanceDescription: string
    isUnderMaintenance: boolean
}

export const GetMaintenance = () => {
    return useQuery<DataTypes>(['getMaintenance'], async () => {
        try {


            const response = await fetchGET('maintenance/getMaintenanceEvent')
            return response.data

        } catch (error) {
            throw error
        }
    },
        {
            refetchOnWindowFocus: true,
        })
}