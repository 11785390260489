import { useTranslation } from "utils/translation/Translation"

interface Props {
    state: boolean
}

export const OverdueRender = ({ state }: Props) => {
    const translate = useTranslation()
    return state ? <>{translate("COL.YES")}</> : <>{translate("COL.NO")}</>
}
