/* eslint-disable react/jsx-no-target-blank */
import { FC, useCallback, useContext, useEffect, useState } from 'react'

import {
	PATH_MODULE,
	PATH_MODULE_INVOICE_PAGE,
	PATH_MODEL_MODULE_INVOICE_PAGE,
	routerNameMap
} from '../../routing/protected/PrivateRoutes'

import { faTriangleExclamation, faFileLines } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub, IMenuItem } from 'ufinet-web-components'
import { AuthContext, Authority, useInternalUser, useTranslator } from 'ufinet-web-functions'

enum AsideMenu {
	NONE,
	CONFIGURATION,
	COMMISSION_PENDING,
	COMMISSION_COMPLETED,
}


const AsideMenuMain: FC = () => {










	const translate = useTranslator()
	const location = useLocation()

	const { userData } = useContext(AuthContext)

	const [active, setActive] = useState(AsideMenu.NONE)

	const activeMenu = (menu: AsideMenu) => () => setActive(menu)
	const isConfigurationActive = () => active === AsideMenu.CONFIGURATION
	const isCommissionPendingActive = () => active === AsideMenu.COMMISSION_PENDING
	const isCommissionCompletedActive = () => active === AsideMenu.COMMISSION_COMPLETED



	const roles = userData?.authorities || []

	const invoicePermissions = Authority.getGfaInvoicePermissions(roles)
	const commissionPermissions = Authority.getCommissionAuthorizationPermissions(roles)
	const isInternal = useInternalUser()



	const menuModuleInvoicesItems: IMenuItem[] = [
		{
			to: PATH_MODEL_MODULE_INVOICE_PAGE,
			title: routerNameMap.get(PATH_MODULE_INVOICE_PAGE),
		},
	].map((it) => ({ ...it, hidden: !invoicePermissions.canRead }))











	return (
		<>



			{invoicePermissions.canRead && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faFileLines} className="fs-2x" />}
					title={translate('TITLE.INVOICES')}
					active={isCommissionPendingActive()}
				>
					{menuModuleInvoicesItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								hasBullet
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={item.title ? translate(item.title) : ''}
								onClick={activeMenu(AsideMenu.COMMISSION_PENDING)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}



		</>
	)
}

export { AsideMenuMain }
